import React, { useEffect, useState } from 'react';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';

export default function Careers() {
  const [jobOpenings, setJobOpenings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 2; // Adjust this value to set the number of jobs per page

  useEffect(() => {
    getDataFromFirebase('jobOpenings', (jobOpenings) => {
      const activeJobOpenings = jobOpenings.filter(job => job.isActive);
      setJobOpenings(activeJobOpenings);
    });
  }, []);

  const totalPages = Math.ceil(jobOpenings.length / jobsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobOpenings.slice(indexOfFirstJob, indexOfLastJob);

  return (
    <>
      <section className="mt-4 p-2">
        <div className="single-slider">
          <div className="container">
            <div
              className="row justify-content-between inner-hero"
              style={{ backgroundImage: "url('/images/careers.webp')" }}
            >
              <div className="col-12">
                <h3
                  className="font-700 mb-0 wow fadeInUp"
                  data-wow-delay="0.0s"
                >
                  Careers
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-section-three top-bottom-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-tittle text-center mb-50">
                <span className="sub-tittle text-capitalize font-600">
                  Join Our Mission
                </span>
                <h2 className="title font-700 text-capitalize">
                  Build A Rewarding <span className="text-red">Career</span> With HC USA
                </h2>
                <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                  Make a real difference. Join our passionate team and empower communities around the world. Explore open positions and find your calling at HC USA.
                </p>
              </div>
            </div>
          </div>

          <div className="row donate-section gy-24 gap-x-25">
            {currentJobs.length > 0 ? (
              currentJobs.map((job, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper" key={index}>
                  <div className="single-donate h-calc">
                    <div className="donate-info">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="donate-info-title w-100">
                          <h4 className="title text-capitalize mb-3">
                            {job.title}
                          </h4>
                          <p className="subtitle mb-0">
                            {job.description}
                          </p>
                          <a
                            href={job.fileUrl} target='_blank'
                            className="btn btn-primary text-white donate-btn w-100 mt-23"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="row justify-content-center">
                <div className="col-xl-7">
                  <p className="text-center">We currently have no job openings. Please check back later for future opportunities.</p>
                </div>
              </div>
            )}
          </div>

          {/* Render pagination only if there are job openings */}
          {jobOpenings.length > 0 && (
            <div style={{ width: "100%" }}>
              <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "black", fontWeight: "bold" }}>
                Page {currentPage} of {totalPages}
              </p>
              <nav className="pagination-nav">
                <ul className="pagination">
                  {currentPage !== 1 &&
                    <li className="page-item" onClick={handlePreviousPage}>
                      <a className="page-link next" href="javascript:void(0)" rel="next" aria-label="Next »"><i className="ri-arrow-left-line"></i></a>
                    </li>
                  }
                  {[...Array(totalPages).keys()].map(page => (
                    <li key={page + 1} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => handlePageClick(page + 1)}>
                        {page + 1}
                      </button>
                    </li>
                  ))}
                  {currentPage !== totalPages &&
                    <li className="page-item" onClick={handleNextPage}>
                      <a className="page-link next" href="javascript:void(0)" rel="next" aria-label="Next »"><i className="ri-arrow-right-line"></i></a>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
