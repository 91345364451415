import { useEffect, useState } from "react";
import { getDataFromFirebase } from "../../firebase/firebaseFunctions";
import { Link } from "react-router-dom";
export default function Appeals() {
  const [appeals, setAppeals] = useState([]);


  useEffect(() => {
    const referencePath = "appeals";

    getDataFromFirebase(referencePath, (appealsArray) => {
      appealsArray = appealsArray.filter((appeal) => {
        return appeal.active;
      });
      setAppeals(appealsArray);
    });
  }, []);

  useEffect(() => {
    console.log("ABC: appeals", appeals);
  });

  function stripHTML(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  
  function truncateText(html, maxLength) {
    const plainText = stripHTML(html);
    if (plainText.length <= maxLength) {
      return plainText;
    }
    return plainText.substring(0, maxLength) + '...';
  }

  const calculateProgress = (goal, amount) => {
    if (amount) {
      const progress = (amount / Number(goal)) * 100;
      return progress.toFixed(1);
    }
    return 0;
  };
  return (
    <>
      <section className="mt-4 p-2">
        <div className="single-slider">
          <div className="container">
            <div
              className="row justify-content-between inner-hero"
              style={{ backgroundImage: "url('/images/appeals.webp')" }}
            >
              <div className="col-12">
                <h3
                  className="font-700 mb-0 wow fadeInUp"
                  data-wow-delay="0.0s"
                >
                  Appeals
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-event section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-tittle text-center mb-50">
                <span className="sub-tittle text-capitalize font-600">
                  Appeals
                </span>
                <h2 className="title font-700 text-capitalize">
                  Urgent appeals that <span className="text-red">Need</span>{" "}
                  your attention
                </h2>
                <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                  Lend your hand today, let's make a difference in saving lives
                  now. Together, we can bring hope and healing to those in need
                </p>
              </div>
            </div>
          </div>
          <div class="row donate-section gy-24 gap-x-25">
            {appeals.map((appeal, index) => (
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper"
                key={index}
              >
                <div className="single-donate h-calc">
                  <div className="donate-img position-relative">
                    <Link to={`/appeal/${appeal.slug}`}>
                      <img
                        className="w-100"
                        src={appeal.thumbnail}
                        alt="Appeal Thumbnail"
                      />
                    </Link>
                  </div>
                  <div className="donate-info">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="donate-info-title w-100">
                        <h4 className="title text-capitalize">
                          <Link to={`/appeal/${appeal.slug}`}>{appeal.title}</Link>
                        </h4>
                        <p className="subtitle mb-3">
                          {truncateText(appeal.description, 80)}
                        </p>
                        {/* <div className="progress custom-progress-two mt-3">
                          <div
                            className="progress-bar"
                            style={{
                              width: `${calculateProgress(
                                appeal.goal,
                                appeal.raisedAmount
                              )}%`,
                            }}
                          >
                            {calculateProgress(
                              appeal.goal,
                              appeal.raisedAmount
                            )}
                            %
                          </div>
                        </div>
                        <div className="flex justify-content-between mt-14 mb-20">
                          <div className="d-flex gap-20 justify-content-between w-100">
                            <div className="charges">
                              <p className="pera mb-0">
                                ${appeal.raisedAmount ? appeal.raisedAmount : 0}
                              </p>
                              <h4 className="title">Raised</h4>
                            </div>
                            <div className="charges">
                              <p className="pera mb-0">${appeal.goal}</p>
                              <h4 className="title">Goal</h4>
                            </div>
                          </div>
                        </div> */}
                        <Link
                          to={`/appeal/${appeal.slug}`}
                          className="btn donate-btn w-100"
                        >
                          Donate Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
              <div class="single-donate h-calc">
                <div class="donate-img position-relative">
                  <a href="donation-details.html"> <img class="w-100" src="/assets/site/images/gallery/donate-1.png" alt="img"/> </a>
                  <div class="donate-badge">
                    <p class="subtitle mb-0">Food</p>
                  </div>
                  
                </div>
                  <div class="donate-info">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="donate-info-title">
                        <h4 class="title text-capitalize"><a href="donation-details.html">We assure you that your donation will be used wisely.</a></h4>
                        <p class="subtitle mb-0">We understand that there are many people organization The seeking support,</p>
                        <div class="progress custom-progress-two mt-3">
                          <div class="progress-bar" style={{width: '25%'}}></div>
                        </div>
                        <div class="flex justify-content-between mt-14 mb-20">
                          <div class="flex gap-20">
                            <div class="charges">
                              <p class="pera mb-0">$15.000</p>
                              <h4 class="title">Goals</h4>
                            </div>
                            <div class="charges">
                              <p class="pera mb-0">$15.000</p>
                              <h4 class="title">Raised</h4>
                            </div>
                          </div>
                          <div class="forward-btn">
                            <i class="ri-reply-fill"></i>
                          </div>
                        </div>
                        <a href="donate-payment.html" class="btn donate-btn w-100">Donate Now</a>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
              <div class="single-donate h-calc">
                <div class="donate-img position-relative">
                  <a href="donation-details.html"> <img class="w-100" src="/assets/site/images/gallery/donate-2.png" alt="img"/> </a>
                  <div class="donate-badge">
                    <p class="subtitle mb-0">Education</p>
                  </div>
                  
                </div>
                <div class="donate-info">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="donate-info-title">
                      <h4 class="title text-capitalize"><a href="donation-details.html">We assure you that your donation will be used wisely.</a></h4>
                      <p class="subtitle mb-0">We understand that there are many people organization The seeking support,</p>
                      <div class="progress custom-progress-two mt-3">
                        <div class="progress-bar" style={{width: '25%'}}></div>
                      </div>
                      <div class="flex justify-content-between mt-14 mb-20">
                        <div class="flex gap-20">
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Goals</h4>
                          </div>
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Raised</h4>
                          </div>
                        </div>
                        <div class="forward-btn">
                          <i class="ri-reply-fill"></i>
                        </div>
                      </div>
                      <a href="donate-payment.html" class="btn donate-btn w-100">Donate Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
              <div class="single-donate h-calc">
                <div class="donate-img position-relative">
                  <a href="donation-details.html"> <img class="w-100" src="/assets/site/images/gallery/donate-3.png" alt="img"/> </a>
                  <div class="donate-badge">
                    <p class="subtitle mb-0">medical</p>
                  </div>
                  
                </div>
                <div class="donate-info">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="donate-info-title">
                      <h4 class="title text-capitalize"><a href="donation-details.html">We assure you that your donation will be used wisely.</a></h4>
                      <p class="subtitle mb-0">We understand that there are many people organization The seeking support,</p>
                      <div class="progress custom-progress-two mt-3">
                        <div class="progress-bar" style={{width: '25%'}}></div>
                      </div>
                      <div class="flex justify-content-between mt-14 mb-20">
                        <div class="flex gap-20">
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Goals</h4>
                          </div>
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Raised</h4>
                          </div>
                        </div>
                        <div class="forward-btn">
                          <i class="ri-reply-fill"></i>
                        </div>
                      </div>
                      <a href="donate-payment.html" class="btn donate-btn w-100">Donate Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
              <div class="single-donate h-calc">
                <div class="donate-img position-relative">
                  <a href="donation-details.html"> <img class="w-100" src="/assets/site/images/gallery/donate-6.png" alt="img"/> </a>
                  <div class="donate-badge">
                    <p class="subtitle mb-0">Food</p>
                  </div>
                  
                </div>
                <div class="donate-info">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="donate-info-title">
                      <h4 class="title text-capitalize"><a href="donation-details.html">We assure you that your donation will be used wisely.</a></h4>
                      <p class="subtitle mb-0">We understand that there are many people organization The seeking support,</p>
                      <div class="progress custom-progress-two mt-3">
                        <div class="progress-bar" style={{width: '25%'}}></div>
                      </div>
                      <div class="flex justify-content-between mt-14 mb-20">
                        <div class="flex gap-20">
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Goals</h4>
                          </div>
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Raised</h4>
                          </div>
                        </div>
                        <div class="forward-btn">
                          <i class="ri-reply-fill"></i>
                        </div>
                      </div>
                      <a href="donate-payment.html" class="btn donate-btn w-100">Donate Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
              <div class="single-donate h-calc">
                <div class="donate-img position-relative">
                  <a href="donation-details.html"> <img class="w-100" src="/assets/site/images/gallery/donate-7.png" alt="img"/> </a>
                  <div class="donate-badge">
                    <p class="subtitle mb-0">Education</p>
                  </div>
                  
                </div>
                <div class="donate-info">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="donate-info-title">
                      <h4 class="title text-capitalize"><a href="donation-details.html">We assure you that your donation will be used wisely.</a></h4>
                      <p class="subtitle mb-0">We understand that there are many people organization The seeking support,</p>
                      <div class="progress custom-progress-two mt-3">
                        <div class="progress-bar" style={{width: '25%'}}></div>
                      </div>
                      <div class="flex justify-content-between mt-14 mb-20">
                        <div class="flex gap-20">
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Goals</h4>
                          </div>
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Raised</h4>
                          </div>
                        </div>
                        <div class="forward-btn">
                          <i class="ri-reply-fill"></i>
                        </div>
                      </div>
                      <a href="donate-payment.html" class="btn donate-btn w-100">Donate Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
              <div class="single-donate h-calc">
                <div class="donate-img position-relative">
                  <a href="donation-details.html"> <img class="w-100" src="/assets/site/images/gallery/donate-8.png" alt="img"/> </a>
                  <div class="donate-badge">
                    <p class="subtitle mb-0">medical</p>
                  </div>
                  
                </div>
                <div class="donate-info">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="donate-info-title">
                      <h4 class="title text-capitalize"><a href="donation-details.html">We assure you that your donation will be used wisely.</a></h4>
                      <p class="subtitle mb-0">We understand that there are many people organization The seeking support,</p>
                      <div class="progress custom-progress-two mt-3">
                        <div class="progress-bar" style={{width: '25%'}}></div>
                      </div>
                      <div class="flex justify-content-between mt-14 mb-20">
                        <div class="flex gap-20">
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Goals</h4>
                          </div>
                          <div class="charges">
                            <p class="pera mb-0">$15.000</p>
                            <h4 class="title">Raised</h4>
                          </div>
                        </div>
                        <div class="forward-btn">
                          <i class="ri-reply-fill"></i>
                        </div>
                      </div>
                      <a href="donate-payment.html" class="btn donate-btn w-100">Donate Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
