import React from 'react'
import { Link } from 'react-router-dom'

export default function DashFooter() {
  return (
    <>
    <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
    <p className="text-white mb-1 mb-md-0">Copyright © 2024 HC USA</p>
    <p className="text-white mb-0">Developed by <Link className="fw-bold text-white" to={'https://adsorse.com'} target='_blank'>Adsorse Technologies</Link> </p>
  </footer>
    </>
  )
}
