import {useEffect, useState} from 'react'
import { FaHeart } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';
export default function HomeNew() {
  const [appeals, setAppeals] = useState([]);

  useEffect(() => {
    const referencePath = "appeals";

    getDataFromFirebase(referencePath, (appealsArray) => {
      appealsArray = appealsArray.filter((appeal) => {
        return appeal.active;
      });
      setAppeals(appealsArray);
    });
  }, []);

  function stripHTML(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  
  function truncateText(html, maxLength) {
    const plainText = stripHTML(html);
    if (plainText.length <= maxLength) {
      return plainText;
    }
    return plainText.substring(0, maxLength) + '...';
  }

  const calculateProgress = (goal, amount) => {
    if (amount) {
      console.log("goal:", goal, "amount:", Number(amount));
      const progress = (amount / Number(goal)) * 100;
      console.log("progress:", progress);
      return progress.toFixed(2);
    }
    return 0;
  };
  return (
    <>
    
    <section className="hero-area">
        <div className="single-slider hero-padding">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xxl-6 col-xl-6 col-lg-6 my-auto">
                <div className="hero-caption-one mb-20">
                  <h4 className="key-title font-700 mb-20 wow fadeInUp" data-wow-delay="0.0s">Stand With Palestine</h4>
                  <h1 className="title font-700 wow fadeInUp" data-wow-delay="0.1s">Stand With <span className='text-red'>Palestine</span>.</h1>
                  <p className="pera wow fadeInUp" data-wow-delay="0.3s">Innocent Palestinian families and children urgently need humanitarian aid and support.</p>
                  <div className="d-flex gap-10 flex-wrap">
                    <Link to={'/appeals'} className="btn-primary-fill hero-btn wow fadeInLeft" data-wow-delay="0.4s">Donate Now</Link>
                    <Link to={'/volunteer'} className="btn-tertiary-fill hero-btn wow fadeInRight" data-wow-delay="0.4s">Volunteer with Us</Link>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 text-center">
                <img src="/assets/site/images/hero/hero-1.webp" alt="img" width="70%" className="tilt-effect wow fadeInUp" data-wow-delay="0.1s"/>
              </div>
            </div>
          </div>
        </div>
    </section>
      <section className="helpful-area section-padding bg-darker">
        <div className="container">
          <div className='row mb-4'>
            <div className='col-12 text-white'>
              <h2 className='text-capitalize'>A Snapshot of Human Concern USA’s Impact in 2023</h2>
            </div>
          </div>
          <div className="row gy-24 mt-4">
            <div className="col-xl-3 col-md-6 col-lg-6 col-6">
              <div className="helpful-card bl-primary br-0 h-calc  wow fadeInLeft" data-wow-delay="0.2s">
                <div className="helpful-card-caption">
                  <h2 className="fw-bolder">307,708</h2>
                  <p className="text-white caption-para mb-0">Individuals benefited from WASH Program</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-lg-6 col-6">
              <div className="helpful-card bl-primary br-0 h-calc  wow fadeInLeft" data-wow-delay="0.2s">
                <div className="helpful-card-caption">
                  <h2 className="fw-bolder">403,467</h2>
                  <p className="text-white caption-para mb-0">Individuals benefited from Zero Hunger Program</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-lg-6 col-6">
              <div className="helpful-card bl-primary br-0 h-calc  wow fadeInLeft" data-wow-delay="0.2s">
                <div className="helpful-card-caption">
                  <h2 className="fw-bolder">2,664</h2>
                  <p className="text-white caption-para mb-0">Children benefited from Child Sponsorship Program</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-lg-6 col-6">
              <div className="helpful-card bl-primary br-0 h-calc  wow fadeInLeft" data-wow-delay="0.2s">
                <div className="helpful-card-caption">
                  <h2 className="fw-bolder">213,776</h2>
                  <p className="text-white caption-para mb-0">Individuals benefited from Qurbani Shares</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero-area">
        <div className="single-slider hero-padding">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xxl-6 col-xl-7 col-lg-7">
                <div className="d-flex gap-44">
                  <img src="/images/decades.webp" alt="img" className="w-100 tilt-effect wow fadeInUp" data-wow-delay="0.1s"/>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-5 col-lg-5 my-auto">
                <div className="hero-caption-one mb-20">
                  <h4 className="key-title font-700 mb-10 mt-20 wow fadeInUp" data-wow-delay="0.0s">A Noble Past, A Bright Future</h4>
                  <h1 className="font-700 wow fadeInUp" data-wow-delay="0.1s">Decades of <span className='text-red'>Combating</span> Poverty & Providing Aid.</h1>
                  <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                    In 1983, a group of compassionate individuals founded one of the first Muslim relief organizations in North America, setting the stage for our impactful journey.
                    <br/>
                    Having disbursed hundreds of millions in aid, sponsored thousands of orphans, and saved countless lives during catastrophes, we take pride in our accomplishments.
                    <br/>
                    Yet, we recognize that our mission is far from complete. Guided by our core vision, mission, and values, we remain steadfast in our pursuit of a more just and equitable world.
                  </p>
                  <div className="d-flex gap-20 flex-wrap">
                    <Link to={'/appeals'} className="btn-primary-fill hero-btn wow fadeInLeft" data-wow-delay="0.4s">Donate Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>

    <section className="our-event bottom-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-tittle text-center mb-50">
                <span className="sub-tittle text-capitalize font-600">Appeals</span>
                <h2 className="title font-700 text-capitalize">Embrace Our <span className='text-red'>Vision</span>, Be Part of the <span className='text-red'>Cause</span>.</h2>
                <p className="pera wow fadeInUp" data-wow-delay="0.3s">Lend your hand today, let's make a difference in saving lives now. Together, we can bring hope and healing to those in need</p>
              </div>
            </div>
          </div>
          <div class="row donate-section gy-24 gap-x-25">
          {appeals.map((campaign, index) => (
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper" key={index}>
              <div className="single-donate h-calc">
                <div className="donate-img position-relative">
                  <Link to={`/appeal/${campaign.slug}`}>
                    <img className="w-100" src={campaign.thumbnail} alt="Campaign Thumbnail" />
                  </Link>
                </div>
                <div className="donate-info">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="donate-info-title w-100">
                      <h4 className="title text-capitalize">
                        <Link to={`/appeal/${campaign.slug}`}>{campaign.title}</Link>
                      </h4>
                      <p className="subtitle mb-3">{truncateText(campaign.description,80)}</p>
                      {/* <div className="progress custom-progress-two mt-3">
                        <div className="progress-bar"
                        style={{
                          width: `${calculateProgress(
                            campaign.goal,
                            campaign.raisedAmount
                          )}%`,
                        }}
                        >
                          {calculateProgress(
                          campaign.goal,
                          campaign.raisedAmount
                        )}
                        %
                        </div>
                      </div>
                      <div className="flex w-100 justify-content-between mt-14 mb-20">
                        <div className="d-flex justify-content-between w-100">
                          <div className="charges">
                            <p className="pera mb-0">${campaign.raisedAmount
                            ? campaign.raisedAmount
                            : 0}</p>
                            <h4 className="title">Raised</h4>
                          </div>
                          <div className="charges">
                            <p className="pera mb-0">${campaign.goal}</p>
                            <h4 className="title">Goal</h4>
                          </div>
                        </div>
                      </div> */}
                      <Link to={`/appeal/${campaign.slug}`} className="btn donate-btn w-100">Donate Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}   
          </div>
        </div>
    </section>

    <section class="helpful-area section-padding">
        <div class="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-tittle text-center mb-50">
                <span className="sub-tittle text-capitalize font-600">Empowering Lives</span>
                <h2 className="title font-700">Providing Food, Education, Clean Water, and Healthcare for Better Futures</h2>
              </div>
            </div>
          </div>
          <div class="row gy-24 mb-4">
            <div class="col-xl-3 col-md-6 col-lg-6 my-2">
              <div class="helpful-card h-calc wow fadeInLeft d-flex flex-column align-items-center" data-wow-delay="0.2s" >
                <div class="helpful-card-icon">
                <i class="ri-plant-fill"></i>
                </div>
                <div class="helpful-card-caption">
                  <h4 class="caption-title text-center">Nutritious Food</h4>
                  <p class="caption-para text-center">Households require monthly deliveries of essential food items to support their family members.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-lg-6 my-2">
              <div class="helpful-card h-calc wow fadeInLeft d-flex flex-column align-items-center" data-wow-delay="0.2s" >
                <div class="helpful-card-icon">
                <i class="ri-graduation-cap-line"></i>
                </div>
                <div class="helpful-card-caption">
                  <h4 class="caption-title text-center">Quality Education</h4>
                  <p class="caption-para text-center">Education serves as the catalyst for transformative change in vulnerable communities.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-lg-6 my-2">
              <div class="helpful-card h-calc wow fadeInLeft d-flex flex-column align-items-center" data-wow-delay="0.2s" >
                <div class="helpful-card-icon">
                <i class="ri-drop-fill"></i>
                </div>
                <div class="helpful-card-caption">
                  <h4 class="caption-title text-center">Clean Water</h4>
                  <p class="caption-para text-center">Clean drinking water elevates the quality and standard of living for everyone.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-lg-6 my-2">
              <div class="helpful-card h-calc wow fadeInLeft d-flex flex-column align-items-center" data-wow-delay="0.2s" >
                <div class="helpful-card-icon">
                <i class="ri-hospital-fill"></i>
                </div>
                <div class="helpful-card-caption">
                  <h4 class="caption-title text-center">Medicine</h4>
                  <p class="caption-para text-center">Healthcare initiatives safeguard the lives of children, seniors, and expectant mothers.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section className="question-area bottom-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <div className="section-tittle mb-20">
                <h2 className="title font-700">Any <span className='text-red'>Questions</span>?</h2>
                <p className="pera">Find answers to common questions about our mission, operations, and how you can get involved.</p>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    How can I donate to Human Concern USA?
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    You can donate to HC USA to the campaign of your choice under the Appeals section of the website by clicking on the "Donate" button. There are options to give one-time or recurring donations and to specify the cause you wish to support.
</div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    How does Human Concern USA use the donations?
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    HC USA uses donations to fund projects across multiple sectors including education, healthcare, water sanitation, and emergency relief, ensuring that funds directly benefit those in need.
</div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    How can I get involved with Human Concern USA?
</button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    You can get involved by volunteering, participating in fundraising events, or joining HC USA’s campaigns. Visit the "Get Involved" section on the website for more details.
</div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Where does Human Concern USA operate?

                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    HC USA operates in various countries around the world, focusing on regions with the most critical needs and where they can make a significant impact. HC USA’s initiatives have spanned over 40 countries in the past 40 years, aiming to uplift communities and provide essential services.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <img className="w-100 d-none d-lg-block tilt-effect" src="/assets/site/images/faq.webp" alt="image"/>
            </div>
          </div>
        </div>
      </section>

      
      
      {/* <section className="blog-section bottom-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-tittle text-center mb-50">
                <span className="sub-tittle text-capitalize font-600">Blog</span>
                <h2 className="title font-700">Our Blog & Feeds</h2>
              </div>
            </div>
          </div>
          <div className="row gy-24">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
              <div className="single-blog h-calc wow fadeInUp" data-wow-delay="0.2s">
                <div className="blog-img position-relative">
                  <a href="blog-details.html"> <img src="/assets/site/images/gallery/blog-1.png" className="img-fluid w-100" alt="img"/> </a>
                </div>
                  <div className="blog-info">
                      <div className="blog-info-title">
                        <div className="flex gap-16 align-items-center">
                          <div className="user flex gap-10 align-items-center">
                            <i className="ri-user-line"></i>
                            <p className="info">By: admin</p>
                          </div>
                          <div className="donate flex gap-10 align-items-center">
                            <i className="ri-chat-3-line"></i>
                            <p className="info">Donation</p>
                          </div>
                        </div>
                        <h4 className="title text-capitalize"><a href="blog-details.html">We assure you that your donation will be used wisely.</a></h4>
                        <p className="subtitle">We understand that there are many people organization The seeking support,</p>
                      </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
              <div className="single-blog h-calc wow fadeInUp" data-wow-delay="0.0s">
                <div className="blog-img position-relative">
                  <a href="blog-details.html"> <img src="/assets/site/images/gallery/blog-2.png" className="img-fluid w-100" alt="img"/> </a>
                 
                </div>
                <div className="blog-info">
                  <div className="blog-info-title">
                    <div className="flex gap-16 align-items-center">
                      <div className="user flex gap-10 align-items-center">
                        <i className="ri-user-line"></i>
                        <p className="info">By: admin</p>
                      </div>
                      <div className="donate flex gap-10 align-items-center">
                        <i className="ri-chat-3-line"></i>
                        <p className="info">Donation</p>
                      </div>
                    </div>
                    <h4 className="title text-capitalize"><a href="blog-details.html">We assure you that your donation will be used wisely.</a></h4>
                    <p className="subtitle">We understand that there are many people organization The seeking support,</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
              <div className="single-blog h-calc wow fadeInUp" data-wow-delay="0.2s">
                <div className="blog-img position-relative">
                  <a href="blog-details.html"> <img src="/assets/site/images/gallery/blog-3.png" className="img-fluid w-100" alt="img"/> </a>
                </div>
                  <div className="blog-info">
                    <div className="blog-info-title">
                      <div className="flex gap-16 align-items-center">
                        <div className="user flex gap-10 align-items-center">
                          <i className="ri-user-line"></i>
                          <p className="info">By: admin</p>
                        </div>
                        <div className="donate flex gap-10 align-items-center">
                          <i className="ri-chat-3-line"></i>
                          <p className="info">Donation</p>
                        </div>
                      </div>
                      <h4 className="title text-capitalize"><a href="blog-details.html">We assure you that your donation will be used wisely.</a></h4>
                      <p className="subtitle">We understand that there are many people organization The seeking support,</p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  )
}
