import React from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap';

export default function OurTeam() {

    const [key, setKey] = React.useState('home');

    return (
        <>
            <section>
                <div className="single-slider mt-4 mb-4 px-2">
                    <div className="container">
                        <div className="row justify-content-between inner-hero" style={{ backgroundImage: "url('/images/about.webp')" }}>
                            <div className="col-12">
                                <h3 className="font-700 mb-0 wow fadeInUp" data-wow-delay="0.0s">Our Team</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="hero-area padding-bottom mb-40">
                <div className="single-slider mt-4 mb-4">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 my-auto mb-4 pb-4">
                                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 d-flex justify-content-center gap-2 bg-light br-100 about-tabs">
                                    <Tab eventKey="home" title="Board Of Directors" className='about-tab'>
                                        <>
                                            <div className='row mb-20'>
                                                <div className='col-12 d-flex justify-content-center align-items-center flex-column text-center'>
                                                <h2 className='heading-main'>Board Of <span className='text-red'>Directors</span></h2>
                                                    <p className='text-para'>
                                                    Leading with compassion and dedication, our Board of Directors ensures the fulfillment of our mission to serve communities in need.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row donate-section gy-24 gap-x-25 d-flex justify-content-center">
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
                                                    <div className="single-donate h-calc">
                                                        <div className="donate-img position-relative">
                                                            {/* <img className="w-100" src="{appeal.thumbnail}"/> */}
                                                        </div>
                                                        <div className="donate-info">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="donate-info-title text-center w-100">
                                                                    <h4 className="title text-capitalize mb-1">Dr. Sohel Anwar</h4>
                                                                    <p className="subtitle mb-0">Chair of the Board</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
                                                    <div className="single-donate h-calc">
                                                        <div className="donate-img position-relative">
                                                            {/* <img className="w-100" src="{appeal.thumbnail}"/> */}
                                                        </div>
                                                        <div className="donate-info">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="donate-info-title text-center w-100">
                                                                    <h4 className="title text-capitalize mb-1">Shamaila Usmani</h4>
                                                                    <p className="subtitle mb-0">Secretary</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
                                                    <div className="single-donate h-calc">
                                                        <div className="donate-img position-relative">
                                                            <img className="w-100" src="{appeal.thumbnail}"/>
                                                        </div>
                                                        <div className="donate-info">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="donate-info-title text-center w-100">
                                                                    <h4 className="title text-capitalize mb-1">Aroba Khan</h4>
                                                                    <p className="subtitle mb-0">Treasurer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </>
                                    </Tab>
                                    <Tab eventKey="profile" title="Senior Management" className='about-tab'>
                                        <>
                                            <div className='row mb-20'>
                                                <div className='col-12 d-flex justify-content-center align-items-center flex-column text-center'>
                                                    <h2 className='heading-main'>Senior <span className='text-red'>Management</span></h2>
                                                    <p className='text-para'>
                                                    Driving our mission forward with commitment and expertise, the Senior Management team oversees operations and strategic initiatives to make a meaningful impact.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row donate-section gy-24 gap-x-25 d-flex justify-content-center">
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper">
                                                    <div className="single-donate h-calc">
                                                        <div className="donate-img position-relative">
                                                            {/* <img className="w-100" src="{appeal.thumbnail}"/> */}
                                                        </div>
                                                        <div className="donate-info">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="donate-info-title text-center w-100">
                                                                    <h4 className="title text-capitalize mb-1">Masum Mahbub</h4>
                                                                    <p className="subtitle mb-0">Chief Operating Officer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
